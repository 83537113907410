/* eslint-disable no-fallthrough */
import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  inject,
} from '@angular/core';
import { environment } from '../environments/environment';
import { UpdatesService } from './update/update.service';
import { RouterOutlet } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationFacade } from '@cca-common/authentication';
import { WINDOW } from '@cca-environment';
import { startDevTools } from '@cca-common/feature-dev';
import { checkForMigrations } from './migrations';

@Component({
  selector: 'cca-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  private appRef = inject(ApplicationRef);
  private authentication = inject(AuthenticationFacade);

  constructor() {
    if (environment.serviceWorker) {
      inject(UpdatesService).enable();
    }

    const window = inject(WINDOW);
    if (window) {
      /**
       * We gonna calculate the initialScaling based of devicePixelRatio more information about why can be found here:
       *
       * https://stackoverflow.com/questions/61434568/windows-10-scaling-default-above-100-causing-website-sizing-issues
       * Because of your client's device pixel ratio, website is probably serving an unexpected responsive version to their screen "real" width.
       * For example: assuming you're using breakpoints like Bootstrap's ones, if their monitor has a resolution width of 1200px (extra large device) but scaling is set to 125%,
       * browser will zoom everything out to 80% and make website serves the version corresponding to a screen width of 960px (large device).
       *
       * (See this site to test "true" and adjusted sizes to a monitor.): https://www.jeffersonscher.com/res/resolution.php
       * Depending of how your website is built, you could work around this by setting the <meta name="viewport" content="width=device-width, initial-scale=1.0" /> correctly
       */
      const initialScaling = 1 / window.devicePixelRatio;
      inject(DOCUMENT)
        .querySelector('meta[name=viewport]')
        ?.setAttribute(
          'content',
          `width=device-width, initial-scale=${initialScaling}`,
        );

      // check and execute migrations on locally stored data
      checkForMigrations(window);
    }

    /**
     * Start dev tools
     */
    startDevTools();

    /**
     * For handling MSAL Angular ( single sign on )
     */
    const msalService = inject(MsalService, {
      optional: true,
    });
    if (msalService) {
      msalService
        .handleRedirectObservable()
        .pipe(takeUntilDestroyed())
        .subscribe((result) => {
          if (result?.accessToken) {
            this.authentication.authenticateSso(result.accessToken);
            this.appRef.tick();
          }
        });
    }

    // schedule task to refreshAccessToken
    setTimeout(() => {
      this.authentication.refreshAccessToken();
    });
  }
}
